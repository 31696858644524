// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
    box-sizing: border-box;
}


/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
    box-sizing: inherit;
}


/**
 * Basic styles for links
 */
a {
    text-decoration: none;
    
    @include on-event {
        text-decoration: none;
    }
}

a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}


/*
 * Make all images responsive
 */
img {
    max-width: 100%;
    max-height: 100%;
}
