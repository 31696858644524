div#gameContainer {
	div#game_overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 10;
		display: none;
	}
	div.game_modal_windows {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 15;
		display: none;
	}
	div.game_rules {
		width: 750px;
		height: auto;
		max-height: 80vh;
		overflow-y: auto;
		background-color: #810913;
		@extend .TheSerifDnevnik_Plain;
		z-index: 20;
		color: #ffffff;
		padding: 30px;
		h2 {
			@extend .TheSerifDnevnik_Bold;
			font-size: 32px;
			text-align: center;
			margin-bottom: 36px;
		}
		p {
			font-size: 15px;

		}
		a.close {
			position: absolute;
			right: 15px;
			top: 15px;
			color: #ffffff; 
			font-size: 20px;
		}
	}
}