@font-face{font-family:'TheSansDnevnikPlain';src:url('../fonts/thesansdnevnik-plain-webfont.eot');src:url('../fonts/thesansdnevnik-plain-webfont.eot?#iefix') format('embedded-opentype'),
url('../fonts/thesansdnevnik-plain-webfont.woff2') format('woff2'),
url('../fonts/thesansdnevnik-plain-webfont.ttf') format('truetype');font-weight:normal;font-style:normal}
@font-face{font-family:'TheSerifDnevnikSemiBold';src:url('../fonts/theserifdnevnik-semibold-webfont.eot');src:url('../fonts/theserifdnevnik-semibold-webfont.eot?#iefix') format('embedded-opentype'),
url('../fonts/theserifdnevnik-semibold-webfont.woff2') format('woff2'),
url('../fonts/theserifdnevnik-semibold-webfont.ttf') format('truetype');font-weight:normal;font-style:normal}
@font-face{font-family:'TheSerifDnevnikBold';src:url('../fonts/theserifdnevnik-bold-webfont.eot');src:url('../fonts/theserifdnevnik-bold-webfont.eot?#iefix') format('embedded-opentype'),
url('../fonts/theserifdnevnik-bold-webfont.woff2') format('woff2'),
url('../fonts/theserifdnevnik-bold-webfont.ttf') format('truetype');font-weight:normal;font-style:normal}

.TheSerifDnevnik_Bold {
	font-family: 'TheSerifDnevnikBold';
}
.TheSerifDnevnik_SemiBold {
	font-family: 'TheSerifDnevnikSemiBold'; 
}
.TheSerifDnevnik_Plain {
	font-family: 'TheSansDnevnikPlain';
}