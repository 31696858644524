div#gameContainer {
	a.button {
		background-color: #5d5d5d;
		font-size: 16px;
		color: #ffffff;
		text-decoration: none;
		height: 42px; 
		line-height: 42px;
		padding: 0px 15px;
		display: inline-block;
		transition: background 0.25s;
		@extend .TheSerifDnevnik_Bold;
		i {
			margin-right: 5px;
		}
		&.active {
			background-color: #ce1d2e;
		}
		&:hover,
		&.red {
			background-color: #ce1d2e;
		}
		&.green {
			background-color: #339900;
			font-size: 13px;
		}
		&[data-puzzle-game-hint],
		&[data-puzzle-game-reset] {
			display: none;
		}
	}
}