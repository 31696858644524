div#gameContainer, div#gameContainer * {
	box-sizing: border-box;
	margin: 0px;
	padding: 0px;
	list-style: none; 
}
div#gameContainer {
	.sprite { 
		background: url('#{$images-url}icons_sprite.png') no-repeat top left; 
		width: 50px; 
		height: 50px;
		&.fb {
			background-position: 0 0; 
		} 
		&.google {
			background-position: 0 -60px; 
		} 
		&.viber {
			background-position: 0 -120px;
		} 
	} 

}

div#gameContainer {
	@extend .TheSerifDnevnik_Plain; 
	width: 1340px;
	height: 1003px;
	overflow: hidden;
	background: transparent url('#{$images-url}main_frame.jpg') no-repeat;
	padding: 50px 40px;
	position: relative;

	div.preloader {
		position: absolute;
		width: 1px;
		height: 1px;
		opacity: 0px;
		visibility: hidden;
		clip: rect(0, 0, 0, 0);
	}

	div.game_frame {
		width: 600px;
		height: 600px;
		background-color: #ffffff;
		padding: 2px 1px 1px 2px; 
		float: left;
		div#game {
			width: 597px;
			height: 597px;
			position: relative;
			div { 
				border-right: 1px solid #ffffff;
				border-bottom: 1px solid #ffffff;
	            position: absolute;
	            transition: all 0.5s;
	            background-repeat: no-repeat;
	        }
	        .empty {
	            background: transparent!important;
	            border: 0px;
	        }
	        span.cover,
	        span.initial_image {
	        	display: block;
	        	position: absolute;
	        	width: 100%;
	        	height: 100%;
	        	border-right: 1px solid #ffffff;
	        	border-bottom: 1px solid #ffffff;
	        	top: 0px;
	        	left: 0px;
	        	right: 0px;
	        	bottom: 0px;
	        	background-color: black;
	        	background-size: cover;
	        	background-repeat: no-repeat;
	        	z-index: 10;
	        	transition: background 0.25s;
	        }
	        span.initial_image {
	        	z-index: 11;
	        	display: none;
	        	&:after {
	        		position: absolute;
	        		top: 0px;
	        		left: 0px;
	        		width: 100%;
	        		height: 100%;
	        		background: rgba(0, 0, 0, 0.5);
	        		content: ' ';
	        		display: block;
	        		z-index: 12;
	        	}
	        	span {
	        		position: absolute;
	        		top: 50%;
	        		left: 50%;
	        		transform: translate(-50%, -50%);
	        		font-size: 18px;
	        		color: #ffffff;
	        		text-align: center;
	        		z-index: 13;
	        		@extend .TheSerifDnevnik_Bold;
	        		label {
	        			display: block;
	        			white-space: nowrap;
	        			padding-bottom: 26px;
	        		}
        			a {
	        			display: inline-block;
	        			margin: 0px 12px;
	        			opacity: 0.7;
	        			transition: opacity 0.25s;
	        			&:hover {
	        				opacity: 1;
	        			}
	        		}
	        	}
	        }
	        &.ready {
	        	-webkit-animation: shake-slow 5s ease-in-out;
				-moz-animation: shake-slow 5s ease-in-out;
				-ms-animation: shake-slow 5s ease-in-out;
	        	div {
	        		-webkit-animation: winnerBox 2s infinite linear;
					-moz-animation: winnerBox 2s infinite linear;
					-ms-animation: winnerBox 2s infinite linear;
	        	}
	        }
		}
	}
	div#puzzle_settings {
		float: right;
		width: 632px;
		height: 600px;
		overflow: hidden;
		background-color: #333333;
		position: relative;
		ul.steps {
			display: table;
			width: 100%;
			border-bottom: 1px solid #5d5d5d;
			li {
				display: table-cell;
				width: 33%;
				border-right: 1px solid #5d5d5d;
				height: 140px;
				padding: 14px;
				color: #ffffff;
				cursor: pointer;
				&:last-child {
					width: 34%;
					border-right: 0px;
				}
				span.check {
					width: 18px;
					height: 18px;
					border: 3px solid #ffffff;
					background-color: #ffffff;
					display: inline-block;
				}
				h3 {
					font-size: 13px;
					display: inline-block;
					text-transform: uppercase;
					vertical-align: top;
					padding-top: 2px;
					padding-left: 6px;
					@extend .TheSerifDnevnik_Plain;
				}
				h2 {
					font-size: 19px;
					padding-top: 15px;
					@extend .TheSerifDnevnik_SemiBold;
				}
				&.active {
					background-color: #ce1d2e; 
					span.check {
						background-color: transparent;
					}
				}
			}
		}
		div#steps_slider {
			margin: 42px 0px 30px;
			[data-puzzle-level-id] {
				opacity: 0;
			}
			div.slick-track [data-puzzle-level-id] {
				opacity: 1;
			}
			div.slick-slide {
				height: 230px;
				outline: none;
				text-align: center;
				padding: 0px 60px;
				position: relative;
				div.passed {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0px;
					left: 0px;
					right: 0px;
					bottom: 0px;
					display: none;
					&.show {
						display: block;
					}
				}
				div.not_passed {
					&.hide {
						display: none;
					}
				}
				div.buttons { 
					text-align: center;
					padding-top: 20px;
					&.hide {
						display: none;
					}
					a.button {
						margin: 0px 10px; 
					}
					i {
						display: inline-block;
					}
				}
				img {
					display: inline-block;
					border: 1px solid #555555;
					border-radius: 50%;
				}
				span {
					font-size: 80px;
					color: #525252;
					display: inline-block;
					vertical-align: top;
					margin-top: 40px;
					margin-left: 40px;
					@extend .TheSerifDnevnik_SemiBold; 
				}
				div.passed_level {
					width: 160px;
					height: 160px;
					display: inline-block;
					border-radius: 50%;
					background-color: #339900;
					border: 2px solid #ffffff;
					@extend .TheSerifDnevnik_SemiBold; 
					color: #ffffff;
					font-size: 20px;
					text-align: center;
					text-transform: uppercase;
					padding-top: 36px;
				}
				i {
					display: block;
					margin-top: 10px;
					color: #ffffff; 
				}
			}
			a.arrows {
				color: #ffffff;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				z-index: 10; 
				transition: color 0.25s;
				&.left {
					left: 20px;
				}
				&.right {
					right: 20px; 
				}
				&.slick-disabled {
					cursor: default;
					color: #5d5d5d;
				}
			}
		}
		div.rules {
			color: #ffffff;
			padding: 30px; 
			position: absolute;
			bottom: 0px;
			left: 0px;
			width: 100%;
			h4 {
				font-size: 22px;
				padding-bottom: 12px;
				@extend .TheSerifDnevnik_SemiBold;
			}
			p {
				font-size: 14px;
				line-height: 22px;  
			}
		}
		div.user_data {
 			padding: 20px 32px;
 			height: 290px;
 			overflow: hidden;
			background-color: #333333;
			position: absolute;
			top: 152px;
			left: 0px;
			width: 100%;
			z-index: 14;
			display: none;
			form {
				padding: 30px 20px;
				padding-top: 0px;
			}
			h2 {
				color: #ffffff;
				font-size: 32px;
				text-align: center;
				margin-bottom: 16px;
			}
			input {
				width: 100%;
				display: block;
				height: 34px;
				padding: 0px 8px;
				margin-bottom: 12px;
				@extend .TheSerifDnevnik_Plain;
				&.error {
					color: #ce1d2e;
					border: 1px solid #ce1d2e;
					&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
					    color:    #ce1d2e;
					}
					&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
					   color:    #ce1d2e;
					   opacity:  1;
					}
					&::-moz-placeholder { /* Mozilla Firefox 19+ */
					   color:    #ce1d2e;
					   opacity:  1;
					}
					&:-ms-input-placeholder { /* Internet Explorer 10-11 */
					   color:    #ce1d2e;
					}
					&::-ms-input-placeholder { /* Microsoft Edge */
					   color:    #ce1d2e;
					}
				}
			}
			span.error {
				color: #ffffff;
				font-size: 15px;
				display: block;
				padding-bottom: 4px;
				display: none;
			}
			a.submit_button {
				display: block;
				text-align: center;
				color: #ffffff;
				font-size: 18px;
				height: 38px;
				line-height: 38px;
				margin-top: 16px;
				background-color: #ce1d2e;
				@extend .TheSerifDnevnik_Bold;
			}
		}
	}

	p.prizes {
		position: absolute;
		bottom: 288px;
		left: 60px;
		color: #ffffff;
		font-size: 22px;
		@extend .TheSerifDnevnik_Bold;
	}
}